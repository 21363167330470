<template>
  <div class="wrapper" v-click-outside="focusOut">
    <FloatLabel :label="label" :optional="optional">
      <div :class="[`${error ? 'invalid' : ''}`]" class="input-wrapper d-flex justify-content-between align-items-center">
        <b-form-input 
          class="shadow-none form-control autocomplete-custom"
          v-model.trim="inputValue"
          type="text"
          :name="name"
          :placeholder="placeholder"
          :class="[`${error ? 'invalid' : ''}`]"
          @click="handleFocus"
          autocomplete="off"
          @keydown.enter='setInputValue(current)'
          @keydown.down='down'
          @keydown.up='up'
          ref="autocompleteCustom"
        />
        <b-icon v-if="inputValue !== '' && clearable" icon="x" class="clear-icon" @click="handleResetValue"></b-icon>
        <img id="caret" src="../../assets/images/icons/ic_drop.svg" @click="handleTogglePanel"/>
      </div>
    </FloatLabel>
    <div class="result-panel" v-if="togglePanel" ref="scrollContainer" @scroll="handleScroll">
      <ul>
        <li 
          :class="{'active': isActive(index), 'selected': inputValue === option}" 
          v-for="(option, index) in paginated"
          :key="option"
          :value="option" 
          @click="setInputValue(index)"
          ref="option"
        >
          {{ option }}
        </li>
      </ul>
    </div>
    <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">{{ error ? helperText : '' }}</div>
    <img
      v-if="isLoading && isLoadAble"
      class="input-spinner"
      src="../../assets/images/input-spinner.gif"
    />
  </div>
</template>
<script>
import { FETCH_DETAIL, CLEAR_INFO } from "@/store/info/actions";
export default {
 components: { 
   FloatLabel: () => import('./FloatLabel.vue')
  },
  name: 'CustomAutocompleteInput',
  inheritAttrs: false,
  model: {
    prop: 'selectValue',
    event: 'change'
  },
  data() {
    return {
      onFocus: false,
      togglePanel: false,
      inputValue: this.selectValue,
      current: 0,
      arrowCounter: 0,
      searchValue: this.selectValue || '',
      limit: 20,
      page: 1,
      isLoading: false,
      isDOMUpdateComplete: false,
    }
  },
  props: {
    selectValue: {
      type: [Number, String],
      default: () => ""
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: String,
    name: {
      type: String,
      default: () => ""
    },
    startsWithFilter: {
      type: Boolean,
      default: () => true,
    },
    isLoadAble: {
      type: Boolean,
      default: () => false,
    },
    error: Boolean,
    helperText: String,
    isFocus: Boolean,
    optional: Boolean,
    placeholder: String,
    clearable: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    filteredOptions() {
      return this.options.filter((option) => {
        const upperCaseOption = option.toUpperCase();
        const upperCaseSearch = this.searchValue.toUpperCase();
        return this.startsWithFilter ? upperCaseOption.startsWith(upperCaseSearch) : upperCaseOption.includes(upperCaseSearch);
      });
    },
    totalPage() {
      return Math.ceil(this.filteredOptions.length / this.limit);
    },
    paginated() {
      return this.page <= this.totalPage ? [...this.filteredOptions].slice(0, this.limit * this.page) : [...this.filteredOptions]
    },
  },
  methods: {
    getOption(value) {
      return this.filteredOptions[value];
    },
    handleFocus() {
      if (!this.isDOMUpdateComplete) {
        this.isLoading = true;
      } else {
        this.handleTogglePanel();
        this.current = 0;
      }
    },
    handleTogglePanel() {
      this.togglePanel = !this.togglePanel;
    },
    handleResetValue() {
      this.inputValue = '';
      if (this.label === 'Make') {
        this.$store.dispatch(FETCH_DETAIL);
        this.$store.dispatch(CLEAR_INFO);
      }
    },
    closeSuggestionPanel() {
      this.togglePanel = false;
      this.arrowCounter = 0;
    },
    setInputValue(index) {
      this.inputValue = this.getOption(index);
      this.searchValue = this.getOption(index);
      this.focusOut();
    },
    up() {
      if(this.current > 0) {
        this.current--;
        if (this.arrowCounter > 0) {
            this.arrowCounter = this.arrowCounter - 1;
            this.fixScrolling();
        }
      }
    },
    down() {
      if(this.current < this.filteredOptions.length - 1) {
        this.current++;
        if (this.arrowCounter < this.filteredOptions.length-1) {
            this.arrowCounter = this.arrowCounter + 1;
            this.fixScrolling();
        }
      }
    },
    isActive(index) {
      return index === this.current;
    },
    fixScrolling() {
      const liHeight = this.$refs.option[this.arrowCounter].clientHeight;
      this.$refs.scrollContainer.scrollTop = liHeight * this.arrowCounter;
    },
    focusOut() {
      this.$emit('change', this.inputValue);
      this.searchValue = '';
      this.filterdOptions = this.options;
      this.closeSuggestionPanel();
      this.$emit('setNextInput');
      this.$refs.autocompleteCustom && this.$nextTick(() => this.$refs.autocompleteCustom.blur());
    },
    setFirstOption() {
      this.inputValue = this.filteredOptions[0];
      this.searchValue = this.filteredOptions[0];
      this.focusOut();
    },
    handleScroll: function () {
      const scrollScreen = this.$refs.scrollContainer;
      const bottomOfScreen = scrollScreen.scrollTop >= scrollScreen.offsetHeight * this.page;
      if (bottomOfScreen && this.page < this.totalPage) {
        this.page++;
      }
    },
  },
  watch: {
    inputValue: {
      handler(value) {
        this.searchValue = value || '';
      }
    },
    filteredOptions() {
      if (this.filteredOptions.length === 1) {
        this.setFirstOption();
      } else {
        if (this.searchValue !== '') {
          const isMatched = this.filteredOptions.some((option) => option.toLowerCase() === this.inputValue.toLowerCase());
          this.inputValue = isMatched ? this.filteredOptions[0] : this.inputValue;
          isMatched && this.focusOut();
        }
      }
      if (this.filteredOptions.length > 1 || this.selectValue !== "") {
        this.isDOMUpdateComplete = true;
        if (this.isLoading) {
          this.isLoading = false;
          this.togglePanel = true;
        }
      }
    }
  },
}
</script>
<style lang="scss">
$gray-color: #ced4da;
select.form-control.invalid, input.form-control.invalid {
  border-color: red !important ;
}
.invalid {
  border-color: red !important;
}
.err-text {
  color: red;
  font-style: italic;
  min-height: 16px;
}
.border-lable-flt {
  margin-bottom: 0;
}
.non-foucus {
  border-color: $gray-color !important;
}
.next-select-input {
  color: #2C2448;
  background-color: #fff;
  border-color: #00BB65 !important;
  outline: 0;
}
.input-wrapper {
  border: 1px solid $gray-color;
  border-radius: 8px;
  position: relative;
  background-color: #fff !important;
  .form-control {
    border: none !important;
  }
}
#caret {
  width: 12px;
  height: 12px;
  margin: 0px 10px;
  cursor: pointer;
}
.wrapper {
  position: relative;
}
.result-panel {
  position: absolute;
  top: 52px;
  left: -2px;
  z-index: 100002;
  background: white;
  width: 100%;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 3px 26px 4px rgb(0 0 0 / 19%);
  padding: 0px;
  margin-left: 2px;
  box-sizing: content-box;
  border: 1px solid rgba(0, 0, 0, .5);
  ul {
    list-style-type: none;
    padding-inline-start: unset;
    margin-bottom: 0;
    li {
      padding: 6px 12px;
      height: 30px;
      text-align: left !important;
      &:hover {
        background: $gray-color;
      }
    }
    .selected, .active {
      background: $gray-color;
    }
  }
}
.clear-icon {
  font-size: 25px;
  cursor: pointer;
}
</style>
